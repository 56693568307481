<template>
  <b-container fluid>
    <b-row class="vh-100" align-v="center" align-h="center">
      <b-col style="min-width: 300px; max-width: 400px">
        <h1>R&eacute;initialiser mon mot de passe</h1>
        <PasswordChangerComponent
          :token="$route.query.token || 'dummy'"
          @change="onChange"
        ></PasswordChangerComponent>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PasswordChangerComponent from "../components/backoffice/PasswordChangerComponent.vue";
export default {
  name: "ForgottenPasswordView",
  components: { PasswordChangerComponent },
  methods: {
    onChange() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
