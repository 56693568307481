<template>
  <section>
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <InputPasswordComponent
      v-if="modeChangement"
      ref="presentPassword"
      label="Mot de passe actuel"
      rules="required"
      v-model="presentPassword"
    ></InputPasswordComponent>
    <InputPasswordComponent
      ref="newPassword"
      label="Nouveau mot de passe"
      rules="required|password"
      v-model="newPassword"
    ></InputPasswordComponent>
    <InputPasswordComponent
      ref="confirmation"
      label="Confirmation"
      :rules="{ required: true, confirmation: newPassword }"
      v-model="confirmation"
    ></InputPasswordComponent>
    <b-row v-if="!noButtons">
      <b-col>
        <b-button block pill variant="outline-danger" @click="clear">
          R&eacute;initialiser
        </b-button>
      </b-col>
      <b-col>
        <b-button block pill variant="danger" @click="validate">
          Valider
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import ErrorComponent from "../ErrorComponent.vue";
import InputPasswordComponent from "../inputs/InputPasswordComponent.vue";
import AccountService from "../../services/account.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "PasswordChangerComponent",
  components: { ErrorComponent, InputPasswordComponent },
  props: ["token", "noButtons"],
  data() {
    return {
      id: uuidv4(),
      error: null,
      presentPassword: "",
      newPassword: "",
      confirmation: "",
    };
  },
  computed: {
    modeChangement() {
      return this.token ? false : true;
    },
  },
  methods: {
    async validate() {
      this.error = null;

      // Validation des champs
      if (this.modeChangement) {
        await this.$refs.presentPassword.validate();
      }
      await this.$refs.newPassword.validate();
      await this.$refs.confirmation.validate();

      // Traitement de la demande selon le mode CHANGEMENT // REINITIALISATION
      try {
        if (this.modeChangement) {
          await AccountService.changePassword(
            this.presentPassword,
            this.newPassword
          );
          await this.$store.dispatch("auth/loadUserAccount");
        } else {
          await AccountService.changeForgottenPassword(
            this.token,
            this.newPassword
          );
        }
        this.clear();
        this.$emit("change");
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
    clear() {
      this.error = null;
      this.presentPassword = "";
      this.newPassword = "";
      this.confirmation = "";
    },
  },
};
</script>
